"use client";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { RxArrowTopRight } from "react-icons/rx";
import { BiSupport } from "react-icons/bi";

export default function Support({
	navigationData,
	serialSearchStatus,
	handleInputChange,
	serialRef,
	disabled,
	handleSubmit,
	close,
}) {
	const router = useRouter();
	return (
		<div className='support-navigation-content-wrapper'>
			<div className='support-main-section'>
				<div className='support-title-head'>
					<h3>FactoryCat Support</h3>
					<p
						style={{
							padding: "0 0",
						}}>
						FactoryCat is fully committed to delivering outstanding
						customer service and ensuring a positive experience for
						all of our valued customers.
					</p>
				</div>
				<ul className='support-left-subnavigation'>
					{navigationData.link
						.find((link) => link.name === "Support")
						?.sublink.map((sublink) =>
							sublink.link !== "Serial Search" ? (
								<li
									key={sublink.id}
									onClick={() => {
										router.push(sublink.location);
										close();
									}}>
									<Link
										href={sublink.location}
										className='card-wrapper'>
										<RxArrowTopRight />
										<h3>{sublink.link}</h3>
										<p>{sublink.description}</p>
										{sublink.link === "Contact us" && (
											<div className='sublink-card-icon'>
												<BiSupport />
											</div>
										)}
									</Link>
								</li>
							) : null
						)}
				</ul>
			</div>
			<div className='serial-search-section'>
				{navigationData.link
					.find((link) => link.name === "Support")
					?.sublink.some(
						(sublink) => sublink.link === "Serial Search"
					) && (
					<div className='search-card-title-wrapper'>
						<div className='card-title'>
							<RxArrowTopRight />
							<h3>Serial Search</h3>
							{serialSearchStatus && <p>{serialSearchStatus}</p>}
						</div>
					</div>
				)}
				<form>
					<input
						type='text'
						id='serial'
						name='serial'
						onChange={handleInputChange}
						ref={serialRef}
						placeholder='Enter Serial Number'
						autoComplete='off'
					/>
					<button
						className='search-button'
						type='submit'
						onClick={handleSubmit}
						disabled={disabled}>
						Search
					</button>
				</form>
			</div>
		</div>
	);
}
