"use client";
import Link from "next/link";
import React from "react";
export default function Footer(props) {
  const footerData = props.data[0];
  const sections = footerData?.attributes.sections;
  return (
    <footer>
      {sections?.map((section) => (
        <ul key={section.id}>
          <li className="heading">{section.section_title}</li>
          {section.links.map((link) => {
            return (
              <li key={link.id}>
                <Link href={link.location}>{link.label}</Link>
              </li>
            );
          })}
        </ul>
      ))}
    </footer>
  );
}
