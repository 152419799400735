"use client";

import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";

import { useRouter } from "next/navigation";
import { sendFormEvent } from "./_ga4/eventHandler";
import Products from "./_components/DropDownMenu/Products";
import Support from "./_components/DropDownMenu/Support";
import { GalleryDropDown } from "./_components/DropDownMenu/GalleryDropDown";
import { PartShopDropDown } from "./_components/DropDownMenu/PartsShopDropDown";
export default function DesktopDropDownMenu(props) {
	const router = useRouter();
	const [visibleParts, setVisibleParts] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");
	const serialRef = useRef(null);
	const [serialSearchStatus, setSerialSearchStatus] = useState(
		"Get detailed information on your industrial floor scrubber."
	);
	const [formStarted, setFormStarted] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const navigationData = props.data;
	const dropDownLinks = props.dropDownLinks;
	const selectedLink = props.selectedLink;
	const [selectedSubLink, setSelectedSublink] = useState(
		"Walk Behind Floor Scrubbers"
	);
	const machines = props.machines;
	const close = props.close;
	const rightColumnStyle = navigationData?.link.reduce((acc, link) => {
		if (
			link.name === selectedLink &&
			link.background_image &&
			link.background_image.data &&
			link.background_image.data.attributes
		) {
			acc.backgroundImage = `url(${link.background_image.data.attributes.url})`;
		}
		return acc;
	}, {});
	const selectedLinkDetails = navigationData?.link.find(
		(link) => link.name === selectedLink
	);

	const rightColumnTitle = selectedLinkDetails?.title || "Default Title";
	const rightColumnDescription =
		selectedLinkDetails?.description || "Default";

	useEffect(() => {
		const handleClick = () => { };

		const telLink = document.querySelector('a[href^="tel:"]');
		telLink.addEventListener("click", handleClick);

		// Cleanup function
		return () => {
			telLink.removeEventListener("click", handleClick);
		};
	}, []);

	const validateSerial = async (serial) => {
		if (!serial) {
			return false;
		}
		let response = await fetch(`/api/getSerial`, {
			method: "POST",
			body: serial,
		});
		response = await response.json();
		return response;
	};

	const handleInputChange = () => {
		if (!formStarted) {
			sendFormEvent("SERIAL_SEARCH", "START"); // Fire event when form interaction starts
			setFormStarted(true);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const serialNumber = serialRef.current.value;
		setSerialSearchStatus("Loading...");
		setDisabled(true);
		sendFormEvent("SERIAL_SEARCH", "SUBMIT");
		let serialStatus = await validateSerial(serialNumber);
		if (!serialStatus.status) {
			setSerialSearchStatus(
				serialStatus.data ||
				"Invalid serial number, please re-enter a valid serial serial number"
			);
			setDisabled(false);
			sendFormEvent("SERIAL_SEARCH", "INVALID/ERROR");
			return;
		} else {
			sendFormEvent("SERIAL_SEARCH", "SUCCESS");
			setSerialSearchStatus(
				"Get detailed information on your industrial floor scrubber."
			);
			setDisabled(false);
			setFormStarted(false);
			router.push(`/serial-results/${serialNumber}`);
			props.close();
		}
	};
	const handleSearch = (event) => {
		event.preventDefault();
		const value = event.target.value.toLowerCase();
		const parts = props.parts;
		setSearchTerm(value);
		if (value === "") {
			setVisibleParts([]); // Reset to all parts if search term is empty
		} else {
			// Filter and then slice the array to limit the results to 10
			const filteredParts = parts
				.filter((part) => part.partNo.toLowerCase().includes(value))
				.slice(0, 6);
			setVisibleParts(filteredParts);
		}
	};

	return (
		<>
			{selectedLink === "Products" && (
				<Products
					dropDownLinks={dropDownLinks}
					selectedSubLink={selectedSubLink}
					setSelectedSublink={setSelectedSublink}
					machines={machines}
					close={close}
				/>
			)}
			{selectedLink === "Support" && (
				<Support
					navigationData={navigationData}
					serialSearchStatus={serialSearchStatus}
					handleInputChange={handleInputChange}
					serialRef={serialRef}
					disabled={disabled}
					handleSubmit={handleSubmit}
					close={props.close}
				/>
			)}
			{selectedLink === "Gallery" && (
				<GalleryDropDown
					demoImages={props.demoImages}
					close={close}
				/>
			)}
			{selectedLink === "Parts Shop" && (

				<PartShopDropDown
					close={close}
					handleSearch={handleSearch}
					searchTerm={searchTerm}
					visibleParts={visibleParts}
				/>
			)}
			{selectedLink === "Login" && (
				<div className='login-navigation-content-wrapper'>
					<div className='login-navigation'>
						<div className='login-background-wrapper'>
							<Image
								src='/Scanchion.png'
								width={500}
								height={500}
								alt='FactoryCat Scanchion'
							/>
							<div className='login-wrapper'>
								<div className='login-section'>
									<div className='login-title-section'>
										<h2>
											Dealer{" "}
											<span className='red'>Access</span>
										</h2>
										<p>
											Login to your FactoryCat dealer
											account.
										</p>
									</div>
									<Link
										href='https://portal.rpscorporation.com'
										className='login-button'
										target='_blank'
										onClick={props.close}>
										Login
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
