"use client";
import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import { RxArrowTopRight } from "react-icons/rx";

export default function Products({
	dropDownLinks,
	selectedSubLink,
	setSelectedSublink,
	machines,
	close,
}) {
	console.log(machines);
	useEffect(() => {
		const handleClick = () => {};

		const telLink = document.querySelector('a[href^="tel:"]');
		telLink.addEventListener("click", handleClick);

		// Cleanup function
		return () => {
			telLink.removeEventListener("click", handleClick);
		};
	}, []);

	return (
		<div className='products-navigation-content-wrapper'>
			<div className='left-product-links'>
				{dropDownLinks?.length > 0 && (
					<ul>
						{dropDownLinks.map((link, index) => (
							<li
								key={index}
								onClick={() => setSelectedSublink(link.link)}
								className={
									selectedSubLink === link.link
										? "selected"
										: ""
								}>
								<div className='products-link'>
									<Link href='#'>{link.link}</Link>
									<RxArrowTopRight />
								</div>
								<p>Big Power in a compact form.</p>
							</li>
						))}
					</ul>
				)}
			</div>
			<div className='right-product-column-wrapper'>
				<div className='selected-sublink'>
					{selectedSubLink}
					<span className='test'>
						{selectedSubLink === "Walk Behind Floor Scrubbers" && (
							<p
								style={{
									padding: "0 0",
								}}>
								Our walk behind floor scrubbers are designed to
								clean industrial spaces quickly and efficiently.
								These machines are perfect for cleaning small to
								medium-sized areas.
							</p>
						)}
						{selectedSubLink === "Ride On Floor Scrubbers" && (
							<p
								style={{
									padding: "0 0",
								}}>
								Our ride-on floor scrubbers are designed to
								clean large industrial spaces quickly and
								efficiently. These machines are perfect for
								cleaning medium to large-sized areas.
							</p>
						)}
						{selectedSubLink === "Ride On Floor Sweepers" && (
							<p
								style={{
									padding: "0 0",
								}}>
								Our ride-on floor sweepers are designed to clean
								large industrial spaces quickly and efficiently.
								These machines are perfect for cleaning medium
								to large-sized areas.
							</p>
						)}
						{selectedSubLink === "Walk Behind Floor Sweepers" && (
							<p
								style={{
									padding: "0 0",
								}}>
								Our walk behind floor sweepers are designed to
								clean small to medium-sized industrial spaces
								quickly and efficiently. These machines are
								perfect for cleaning small to medium-sized
								areas.
							</p>
						)}
					</span>
				</div>
				<div className='right-product-content'>
					{machines.map((machine) => {
						if (
							machine.attributes.style.data.attributes.style ===
							selectedSubLink
						) {
							return (
								<Link
									href={`/models/${machine.attributes.slug}`}
									onClick={close}
									key={machine.attributes.id}
									className='machine'>
									<Image
										src={
											machine.attributes.main_image.data
												.attributes.url
										}
										width={180}
										height={180}
										alt={
											machine.attributes.main_image.data
												.attributes.alternativeText ||
											`FactoryCat ${machine.attributes.name} || ${machine.attributes.style.data.attributes.style}`
										}
									/>
									<h3>{machine.attributes.name}</h3>
								</Link>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
}
