import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { FaExternalLinkAlt } from 'react-icons/fa';

export const PartShopDropDown = ({ close, handleSearch, searchTerm, visibleParts }) => {
    const router = useRouter();
    const [excludedParts, setExcludedParts] = useState(new Set());

    const handleImageError = (partNo) => {
        setExcludedParts((prev) => new Set([...prev, partNo]));
    };

    return (
        <div className='parts-shop-navigation-content-wrapper'>
            <div className='parts-shop-navigation-left'>
                <div className='title-section'>
                    {visibleParts === null ? (
                        <h2>Search for a part to display the results.</h2>
                    ) : (
                        <h2>Search Results</h2>
                    )}
                    <p>
                        FactoryCat floor scrubber parts are available for purchase online. We have a large selection of parts available for purchase. If you need help finding a part, please contact us.
                    </p>
                </div>
                <ul className='parts-shop-navigation-results'>
                    {visibleParts &&
                        visibleParts
                            .filter((part) => !excludedParts.has(part.partNo))
                            .map((part) => (
                                <li
                                    key={part.partNo}
                                    onClick={() => {
                                        router.push(`/parts/${part.partNo}`);
                                        close();
                                    }}>
                                    <Link
                                        className='card-wrapper'
                                        href={`/parts/${part.partNo}`}>
                                        <img
                                            width={90}
                                            height={90}
                                            src={`https://www.portal.rpscorporation.com/images/partImages/${part.partNo}_01.JPG`}
                                            onError={() => handleImageError(part.partNo)}
                                            alt={`Floor Scrubber Part - ${part.partNo}`}
                                        />
                                        <div className='card-details'>
                                            <h3>{part.partNo}</h3>
                                            <p>{part.description || "No description available."}</p>
                                        </div>
                                    </Link>
                                </li>
                            ))}
                    {visibleParts && visibleParts.length === 0 && <>No parts found</>}
                </ul>
            </div>
            <div className='parts-shop-navigation-right'>
                <div className='title-section'>
                    <h3>Parts Search</h3>
                </div>
                <form>
                    <input
                        type='text'
                        placeholder='Search by part number'
                        value={searchTerm}
                        onChange={handleSearch}
                        className='search-input'
                    />
                </form>
                <div className='parts-shop-external-section'>
                    <p>
                        You can also visit our official parts shop to view our large selection of parts, including brushes, squeegees, and more.
                    </p>
                    <Link
                        className='external-parts-shop-link'
                        href='https://rps-shop.com'
                        target='_blank'
                        onClick={close}>
                        Go to Parts Shop
                        <FaExternalLinkAlt />
                    </Link>
                </div>
            </div>
        </div>
    );
};
