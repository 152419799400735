"use client";
import Image from "next/image";
import React, { useState } from "react";
import fc_full_logo from "/public/full_fc_logo.png";
import Link from "next/link";
import DesktopDropDownMenu from "./DesktopDropDownMenu";
import { useRouter } from "next/navigation";

export default function DesktopNavigation({
  navigationData,
  machineData,
  demoImages,
  parts,
}) {
  const navData = navigationData[0]?.attributes;
  const machines = machineData;
  const router = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [dropDownLinks, setDropDownLinks] = useState(null);
  const [hoveredItem, setHoveredItem] = useState("");

  const handleClose = (event) => {
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div className="desktop-navigation">
        <Image
          src={fc_full_logo}
          width={500}
          height={500}
          alt="FactoryCat"
          onClick={() => router.push("/")}
        />
        <ul>
          {navData?.link.map((links) => {
            if (links.name === "Home" || links.name === "1 262-681-3583") {
              return (
                <li
                  key={links.id}
                  onMouseEnter={() => {
                    setSelectedLink(links.name);
                    setHoveredItem(links.name || "");
                    setIsDropdownOpen(false);
                  }}
                >
                  <Link href={links.location}>{links.name}</Link>
                </li>
              );
            } else if (links.name === "Support") {
              return (
                <li
                  key={links.id}
                  onMouseEnter={() => {
                    setSelectedLink(links.name);
                    setDropDownLinks(links.sublink || []);
                    setIsDropdownOpen(true);
                    setHoveredItem(links.name || "");
                  }}
                  onClick={() => {
                    setSelectedLink(links.name);
                    setDropDownLinks(links.sublink || []);
                    setIsDropdownOpen(true);
                    setHoveredItem(links.name || "");
                  }}
                >
                  <Link href={"#"}>{links.name}</Link>
                </li>
              );
            } else {
              return (
                <li
                  key={links.id}
                  onMouseEnter={() => {
                    setSelectedLink(links.name);
                    setDropDownLinks(links.sublink || []);
                    setIsDropdownOpen(true);
                    setHoveredItem(links.name || "");
                  }}
                >
                  <Link href={"#"}>{links.name}</Link>
                </li>
              );
            }
          })}

          <li>
            <Link
              href="/contact"
              onClick={() => {
                setHoveredItem("");
                setIsDropdownOpen(false);
              }}
              className="contact-link"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div
        className={
          isDropdownOpen ? `desktop_dropdown open` : `desktop_dropdown`
        }
        onMouseLeave={() => {
          setIsDropdownOpen(false);
          setHoveredItem("");
        }}
      >
        <DesktopDropDownMenu
          data={navData}
          machines={machines}
          selectedLink={selectedLink}
          dropDownLinks={dropDownLinks}
          close={handleClose}
          demoImages={demoImages}
          parts={parts}
        />
      </div>
    </>
  );
}
